import CustomProTable from "@components/CustomProTable"
import { LayoutAdmin } from "@components/LayoutAdmin"
import React from "react"

const columns = [
  {
    title: "Nome do ensaio",
  },
  {
    title: "modelo",
  },
  {
    title: "publicado em ",
  },
  {
    title: "categoria",
  },
]

const AdminSubscribersUsersPage = () => {
  return (
    <LayoutAdmin
      title="Página de assinantes"
      subTitle="página de teste do menu"
    >
      <CustomProTable columns={columns} />
    </LayoutAdmin>
  )
}

export default AdminSubscribersUsersPage
